import LoadingOverlay from '@rsa-digital/evo-shared-components/components/LoadingOverlay';
import { graphql, PageProps } from 'gatsby';
import React, { useEffect, useReducer } from 'react';
import LoadMtaQuoteWrapper from 'components/ApiRequestWrapper/LoadMtaQuoteWrapper';
import LoadPolicyWrapper from 'components/ApiRequestWrapper/LoadPolicyWrapper';
import Layout from 'components/Layout';
import MtaStartDatePage from 'components/MtaStartDate/MtaStartDatePage';
import useMtaStartDatePage from 'components/MtaStartDate/useMtaStartDatePage';
import { trackCheckoutEvent } from 'helpers/ecommerceTracking';
import { getQueryParam } from 'helpers/getQueryParam';
import useApiRequestHandler from 'helpers/useApiRequestHandler';
import { usePageTracking } from 'helpers/usePageTracking';
import withPolicyNumber, { PolicyNumberProps } from 'helpers/withPolicyNumber';
import { useRemoveDriverMtaDetails } from 'state/formData/mta/removeDriver';
import { useMtaQuote } from 'state/mta/mtaQuote';
import { useUser } from 'state/user/state';
import { CsMeta } from 'types/contentStack';

type RemoveDriverProps = {
  data: {
    csMtaRemoveDriver: {
      meta: CsMeta;
    };
  };
};

export const query = graphql`
  query {
    csMtaRemoveDriver {
      meta {
        meta_title
      }
    }
  }
`;

const RemoveDriver: React.FC<RemoveDriverProps & PolicyNumberProps & PageProps> = ({
  data: {
    csMtaRemoveDriver: { meta },
  },
  policyNumber,
  location,
}) => {
  const driverId = getQueryParam(location, 'driverId');

  const { isLoading, loadingMessage, requestHandler } = useApiRequestHandler();
  const { startDateFormProps, moveNextOrGenerateMta } = useMtaStartDatePage(
    policyNumber,
    'Remove a driver',
    requestHandler,
    true
  );

  const [, updateRemoveDriverDetails] = useRemoveDriverMtaDetails();

  useEffect(() => {
    if (!driverId) {
      throw new Error('DriverId query param missing');
    } else {
      updateRemoveDriverDetails({ driverId });
    }
  }, [driverId, updateRemoveDriverDetails]);

  const [formSubmitted, setFormSubmitted] = useReducer(() => true, false);

  const moveNext = (): void => {
    if (!formSubmitted) {
      moveNextOrGenerateMta();
    }
    setFormSubmitted();
  };

  const useMoveNextIfExistingMtaRequest = (): void => {
    const mtaQuote = useMtaQuote();

    useEffect(() => {
      if (mtaQuote !== null) {
        moveNext();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mtaQuote]);
  };

  useMoveNextIfExistingMtaRequest();

  useEffect(() => trackCheckoutEvent(1, null, 'MTA'), []);
  const [user] = useUser();
  usePageTracking(meta.meta_title, !!user?.isLoggedIn);

  return (
    <Layout meta={meta} pageType="mtaAndRenewal">
      <LoadPolicyWrapper policyNumber={policyNumber}>
        <LoadMtaQuoteWrapper policyNumber={policyNumber}>
          {isLoading && <LoadingOverlay loadingMessage={loadingMessage || ''} />}
          <MtaStartDatePage
            formState={startDateFormProps.formState}
            updateFormState={startDateFormProps.updateFormState}
            moveNext={moveNext}
          />
        </LoadMtaQuoteWrapper>
      </LoadPolicyWrapper>
    </Layout>
  );
};

export default withPolicyNumber(RemoveDriver);
