import { graphql, useStaticQuery } from 'gatsby';
import { CsQuestion, CsQuestionWithPlaceholder } from 'types/contentStack';
import {
  Question,
  QuestionWithOptionalPlaceholder,
  QuestionWithPlaceholder,
} from 'types/forms';
import { trackTooltipClick } from './eventTracking';

type TooltipDefaults = {
  open: string;
  close: string;
};

type CsGlobalDefaults = {
  csMotorGlobalConfig: {
    default_tooltip_labels: TooltipDefaults;
    select_field_default_placeholder: string;
  };
};

const globalConfigQuery = graphql`
  query {
    csMotorGlobalConfig {
      default_tooltip_labels {
        open
        close
      }
      select_field_default_placeholder
    }
  }
`;

type QuestionProcessor = {
  processQuestion: (csQuestion: CsQuestion) => Question;
  processQuestionWithOptionalPlaceholder: (
    csQuestion: CsQuestionWithPlaceholder
  ) => QuestionWithOptionalPlaceholder;
  processQuestionWithPlaceholder: (
    csQuestion: CsQuestionWithPlaceholder
  ) => QuestionWithPlaceholder;
};

const useQuestionProcessor = (): QuestionProcessor => {
  const {
    select_field_default_placeholder: defaultPlaceholderText,
    default_tooltip_labels: defaultTooltipLabels,
  } = useStaticQuery<CsGlobalDefaults>(globalConfigQuery).csMotorGlobalConfig;

  const processQuestion = (csQuestion: CsQuestion): Question => {
    const openTooltipText =
      csQuestion.main_details.tooltip.tooltip_open || defaultTooltipLabels.open;
    const closeTooltipText =
      csQuestion.main_details.tooltip.tooltip_close || defaultTooltipLabels.close;
    return {
      questionText: csQuestion.main_details.question_text,
      explanatoryText: csQuestion.main_details.explanatory_text,
      tooltip: csQuestion.main_details.tooltip.tooltip_text?.trim()
        ? {
            body: csQuestion.main_details.tooltip.tooltip_text.trim(),
            buttonText: {
              open: openTooltipText,
              close: closeTooltipText,
            },
            onExpand: () =>
              trackTooltipClick(
                'Expand',
                openTooltipText,
                csQuestion.main_details.question_text
              ),
            onCollapse: () =>
              trackTooltipClick(
                'Collapse',
                closeTooltipText,
                csQuestion.main_details.question_text
              ),
          }
        : undefined,
      alertText: csQuestion.main_details.alert_text,
    };
  };

  const processQuestionWithOptionalPlaceholder = (
    csQuestion: CsQuestionWithPlaceholder
  ): QuestionWithOptionalPlaceholder => ({
    ...processQuestion(csQuestion),
    placeholder: csQuestion.main_details.placeholder,
  });

  const processQuestionWithPlaceholder = (
    csQuestion: CsQuestionWithPlaceholder
  ): QuestionWithPlaceholder => ({
    ...processQuestion(csQuestion),
    placeholder: csQuestion.main_details.placeholder || defaultPlaceholderText,
  });

  return {
    processQuestion,
    processQuestionWithOptionalPlaceholder,
    processQuestionWithPlaceholder,
  };
};

export default useQuestionProcessor;
