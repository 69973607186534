import { InputReactElement } from '@rsa-digital/evo-shared-components/components/Form/inputTypes';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React from 'react';
import { Question } from 'types/forms';
import { AnimatedField } from './styles';

type QuestionFieldProps = {
  question: Question;
  errorText?: string;
  children: InputReactElement;
  id?: string;
} & ComponentProps;

const QuestionField: React.FC<QuestionFieldProps> = ({
  question,
  errorText,
  children,
  id,
  ...props
}) => (
  <AnimatedField
    {...componentProps(props)}
    id={id}
    label={question.questionText}
    explanatoryText={question.explanatoryText}
    tooltip={question.tooltip}
    alertBody={question.alertText}
    errorText={errorText}
    alignLeft>
    {children}
  </AnimatedField>
);

export default QuestionField;
