import Field from '@rsa-digital/evo-shared-components/components/Form/Field';
import styled from 'styled-components';

// TODO: DTN-619: check when this field should be animated and only do it then.
// (In pet it only animates if the field is added, i.e. not on page load.
export const AnimatedField = styled(Field)`
  position: relative;

  /*  We use 'top: -30px' rather than 'transform: translateY(-30px)' here as 
      as the use of transform creates a new stacking context, which results in
      the overflow of the question fields sometimes being cut-off e.g. the :active
      styling of the radio inputs.
  */
  @keyframes fadeInTop {
    0% {
      opacity: 0;
      top: -30px;
    }

    100% {
      opacity: 1;
      top: 0px;
    }
  }

  animation: fadeInTop 0.3s ease-out forwards;
`;
